import PbButton from "@/components/input/pbButton/pbButton";
import PbInput from "@/components/input/pbInput/pbInput";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import ModalInputWrapper from "@/components/util/modalInputWrapper";
import useCesStrHeadline from "@/hooks/useCesStrHeadline";
import useCesStrRichText from "@/hooks/useCesStrRichText";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import useConfirmModal from "@/hooks/useConfirmModal";
import useStrButton from "@/hooks/useStrButton";
import {
  CFG_CONTENT_ELEMENT_SETTING_NAME,
  ceSettingById,
  getNestedCeSettingsData,
  valueFromStoreSetting,
} from "@/services/ceSettings/ceSettingsService";
import {
  moveInArrayAction,
  removeFromArrayAction,
  updateAttributeAction,
} from "@/store/slices/cmsEdit/cmsEditSlice";
import { cmsAddNestedContentElementThunk } from "@/store/slices/cmsEdit/cmsPageThunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { ContentElementCarouselStoreSetting } from "@/types/ceSettings/ceSettings";
import { HeadlineHeading } from "@/types/config/enums";
import { CECarousel, CECarouselSlide } from "@/types/content-elements";
import { MEDIA_TYPE_IMG } from "@/utils/constants";
import { getIdOrNewId } from "@/utils/util";
import React, { useState } from "react";
import CmsContentButton from "../cmsContent/cmsContentButton/cmsContentButton";
import CmsContentRichText from "../cmsContent/cmsContentRichText/cmsContentRichText";
import CmsGeneralConfigFrame from "../cmsGeneralConfigFrame/cmsGeneralConfigFrame";
import CmsMediaSelector from "../cmsMediaSelector/cmsMediaSelector";

interface CmsNestedContentCarouselProps {
  contentElement: CECarousel;
  cePosition: number;
}

export default function CmsNestedContentCarousel(
  props: CmsNestedContentCarouselProps
) {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();

  const currentElement = useAppSelector((state) => {
    if (props.cePosition || props.cePosition === 0) {
      return state.cmsEdit.data?.draftPage?.content[props.cePosition];
    }
    return undefined;
  });

  const contentElementSettingsId = valueFromStoreSetting(
    currentElement[CFG_CONTENT_ELEMENT_SETTING_NAME]
  );

  const ceSettings = ceSettingById(contentElementSettingsId)
    ?.setting! as ContentElementCarouselStoreSetting;

  const headlineSetting = useCesStrHeadline({
    typography: props.contentElement.cfgStrInnerHeadlineTypography,
    font: props.contentElement.cfgStrInnerHeadlineFont,
    fontColor: props.contentElement.cfgStrInnerHeadlineFontColor,
    cfgHeadlineType: props.contentElement.cfgHeadlineType as HeadlineHeading,
    ceSettings: getNestedCeSettingsData(ceSettings.headline),
    ceSettingHeadline: ceSettings.headlineheading,
  });

  const subheadlineSetting = useCesStrHeadline({
    typography: props.contentElement.cfgStrInnerSubheadlineTypography,
    font: props.contentElement.cfgStrInnerSubheadlineFont,
    fontColor: props.contentElement.cfgStrInnerSubheadlineFontColor,
    cfgHeadlineType: props.contentElement.cfgSubheadlineType as HeadlineHeading,
    ceSettings: getNestedCeSettingsData(ceSettings.subheadline),
    ceSettingHeadline: ceSettings.subheadlineheading,
  });

  const { buttonClassName } = useStrButton(
    props.contentElement.cfgStrInnerButtonButton,
    ceSettings.button
  );

  const { richTextClassName, richtextEditorSettings } = useCesStrRichText(
    ceSettings.richtext,
    ceSettings.richtext
  );

  const nestedElementAttributes = useAppSelector((state) => {
    return state.cmsEdit.data?.availableElements?.find(
      (element: any) => props.contentElement.__component === element.uid
    )?.nestedElement;
  });

  const [deleteIndex, setDeleteIndex] = useState<number>(0);
  const { showConfirmModal } = useConfirmModal(() => {
    dispatch(
      removeFromArrayAction({
        attributePath: `draftPage.content[${props.cePosition}].content`,
        position: deleteIndex,
      })
    );
  });

  const headlineHeadingTag = headlineSetting.HeadingTag;
  const subheadlineHeadingTag = subheadlineSetting.HeadingTag;

  return (
    <>
      {props.contentElement?.content?.map(
        (
          nestedElement: CECarouselSlide,
          index: number,
          arr: Array<CECarouselSlide>
        ) => {
          return (
            <React.Fragment
              key={`carousel-${props.cePosition}-element-${index}`}
            >
              <CmsGeneralConfigFrame
                initialFocus={index === arr.length - 1}
                title={tCms(nestedElementAttributes.uid)}
                key={getIdOrNewId(nestedElement, index)}
                frameOnHover
                position={index}
                arrayLength={props.contentElement?.content?.length}
                moveUp={() =>
                  dispatch(
                    moveInArrayAction({
                      attributePath: `draftPage.content[${props.cePosition}].content[${index}]`,
                      direction: "up",
                    })
                  )
                }
                moveDown={() =>
                  dispatch(
                    moveInArrayAction({
                      attributePath: `draftPage.content[${props.cePosition}].content[${index}]`,
                      direction: "down",
                    })
                  )
                }
                hideAddCEButton
                onAddBtnClick={() =>
                  dispatch(
                    cmsAddNestedContentElementThunk(
                      nestedElementAttributes,
                      props.cePosition,
                      "content",
                      index + 1
                    )
                  )
                }
                onDelete={() => {
                  setDeleteIndex(index);
                  showConfirmModal({
                    title: tCms(
                      "nestedContent-deleteContentElementConfirmation",
                      {
                        contentElement: tCms(nestedElementAttributes.uid),
                      }
                    ),
                    content: tCms(
                      "nestedContent-deleteContentElementConfirmationLong",
                      {
                        contentElement: tCms(nestedElementAttributes.uid),
                      }
                    ),
                    acceptBtnText: tCms("delete"),
                    denyBtnText: tCms("cancel"),
                    xIsDeny: true,
                    icon: "triangle-exclamation-light",
                  });
                }}
              >
                <div className="cms-modal-input-wrapper-container">
                  <ModalInputWrapper
                    label={tCms("headline-primaryHeadline")}
                    description={tCms("headlineDescription")}
                  >
                    <PbInput
                      withCmsStyle
                      defaultValue={nestedElement.title}
                      fullWidth
                      placeholder={`${tCms(
                        "headline-primaryHeadline"
                      )} <${headlineHeadingTag}>`}
                      onChange={(e) =>
                        dispatch(
                          updateAttributeAction({
                            attributePath: `draftPage.content[${props.cePosition}].content[${index}].title`,
                            value: e.target.value,
                          })
                        )
                      }
                    />
                  </ModalInputWrapper>
                  <ModalInputWrapper
                    label={tCms("headline-secondaryHeadline")}
                    description={tCms("subheadlineDescription")}
                  >
                    <PbInput
                      withCmsStyle
                      defaultValue={nestedElement.secondHeadline}
                      fullWidth
                      placeholder={`${tCms(
                        "headline-secondaryHeadline"
                      )} <${subheadlineHeadingTag}>`}
                      onChange={(e) =>
                        dispatch(
                          updateAttributeAction({
                            attributePath: `draftPage.content[${props.cePosition}].content[${index}].secondHeadline`,
                            value: e.target.value,
                          })
                        )
                      }
                    />
                  </ModalInputWrapper>
                  <ModalInputWrapper
                    label={tCms("richtext")}
                    description={tCms("richtextDescription")}
                  >
                    <CmsContentRichText
                      className={richTextClassName}
                      ceSettings={richtextEditorSettings}
                      content={nestedElement.richTextContent}
                      cmsFieldContent={`content[${index}].richTextContent`}
                      position={props.cePosition}
                    />
                  </ModalInputWrapper>
                  <ModalInputWrapper
                    label={tCms("button")}
                    description={tCms("buttonDescription")}
                  >
                    <CmsContentButton
                      cmsFieldGetParams={`content[${index}].linkGetParams`}
                      cmsFieldHrefLang={`content[${index}].linkHrefLang`}
                      cmsFieldRel={`content[${index}].linkRel`}
                      cmsFieldTarget={`content[${index}].linkTarget`}
                      cmsFieldText={`content[${index}].linkText`}
                      cmsFieldUrl={`content[${index}].linkUrl`}
                      cmsFieldAnchorName={`content[${index}].linkAnchor`}
                      cmsPopoverOpenerId=""
                      cmsPosition={props.cePosition}
                      getParams={nestedElement.linkGetParams}
                      hrefLang={nestedElement.linkHrefLang}
                      popoverPlacement="top"
                      rel={nestedElement.linkRel}
                      target={nestedElement.linkTarget}
                      text={nestedElement.linkText}
                      url={nestedElement.linkUrl}
                      anchorName={nestedElement.linkAnchor}
                      className={buttonClassName}
                      disableAutoWidthInEditor
                    />
                  </ModalInputWrapper>
                  <CmsMediaSelector
                    maxHeight={156}
                    mediaElement={nestedElement}
                    defaultValue={MEDIA_TYPE_IMG}
                    mediaOptions={[MEDIA_TYPE_IMG]}
                    resetAllAttributesOnMediaTypeChange
                    attributeNameImage="img"
                    onChangeImage={(onChangeImageValues) =>
                      dispatch(
                        updateAttributeAction({
                          attributePath: `draftPage.content[${props.cePosition}].content[${index}].img`,
                          value: onChangeImageValues.img,
                        })
                      )
                    }
                  />
                </div>
              </CmsGeneralConfigFrame>
              <hr />
            </React.Fragment>
          );
        }
      )}
      <ModalInputWrapper
        label={tCms("addNewElement")}
        description={tCms("nestedContent-addContentElement", {
          contentElement: tCms("pb.gllrysld"),
        })}
      >
        <PbButton
          onClick={() =>
            dispatch(
              cmsAddNestedContentElementThunk(
                nestedElementAttributes,
                props.cePosition,
                "content"
              )
            )
          }
          startIcon={
            <PbIcon
              name="plus-light"
              width={15}
              height={15}
              className="svg-fill-white"
            />
          }
        >
          {tCms("add")}
        </PbButton>
      </ModalInputWrapper>
    </>
  );
}
